import { Controller as BaseController } from "@hotwired/stimulus"

export class Controller extends BaseController {
  static targets = ["mainField"]
  static values = {
    codeLength: Number,
  }

  connect() {
    this._hideCaret()
  }

  isFilled() {
    return this._allFieldsAreFilled()
  }

  checkAllowance(e) {
    if (!this._isValidOtpField(e)) {
      e.preventDefault()
    } else {
      const currentInput = e.currentTarget
      if (currentInput.value.length >= 1 && !this._isAllowedKeys(e)) {
        e.preventDefault()
        currentInput.value = e.key
        this._focusNext(currentInput)
        this._updateMainField()
      }
    }
  }

  handleInputEvent(e) {
    const currentInput = e.currentTarget
    const inputValue = e.target.value

    if (inputValue.length === this.codeLengthValue) {
      this._handleFullCodeInput(inputValue, currentInput)
    } else {
      const digitValue = inputValue.slice(-1) // Получаем последний введенный символ
      this._handleSingleInput(digitValue, currentInput)
    }

    this._updateMainField()
  }

  handleKeyUp(e) {
    const currentInput = e.currentTarget
    if (e.key === "Backspace") {
      const prev = this.element.querySelector(`input#${currentInput.dataset.previous}`)
      currentInput.value = ""
      if (prev !== null) {
        prev.focus()
      }
    } else if (e.key === "ArrowLeft") {
      const prev = this.element.querySelector(`input#${currentInput.dataset.previous}`)
      if (prev !== null) {
        prev.focus()
      }
    } else if (e.key === "ArrowRight") {
      this._focusNext(currentInput)
    }
  }

  async handlePaste(e) {
    e.preventDefault()
    try {
      const paste = await navigator.clipboard.readText()
      const pasteArray = paste.split("")
      let inputIndex = 1

      pasteArray.forEach((char) => {
        if (this._isAllowedValue(char) && inputIndex <= this.codeLengthValue) {
          const input = this.element.querySelector(`input#digit-${inputIndex}`)
          if (input) {
            input.value = char
            inputIndex++
          }
        }
      })

      this._updateMainField()
    } catch (err) {
      console.error("Failed to read clipboard contents: ", err)
    }
  }

  _handleSingleInput(digitValue, currentInput) {
    if (this._isAllowedValue(digitValue)) {
      currentInput.value = digitValue
      this._focusNext(currentInput)
    } else {
      currentInput.value = "" // Очищаем значение, если символ недопустим
    }
  }

  _handleFullCodeInput(inputValue, currentInput) {
    for (let i = 0; i < inputValue.length; i++) {
      const digitValue = inputValue[i]

      if (this._isAllowedValue(digitValue)) {
        const input = this.element.querySelector(`input#digit-${i + 1}`)
        input.value = digitValue
      } else {
        currentInput.value = "" // Очищаем значение, если символ недопустим
        break
      }
    }
  }

  _focusNext(currentInput) {
    const next = this.element.querySelector(`input#${currentInput.dataset.next}`)
    if (next !== null) {
      next.focus()
    }
  }

  _updateMainField() {
    let otpCode = ""
    for (let i = 1; i <= this.codeLengthValue; i++) {
      otpCode += this.element.querySelector(`input#digit-${i}`).value
    }

    this._dispatchChanges(otpCode)
    this.mainFieldTarget.value = otpCode
    this._checkIfAllFilled()
  }

  _dispatchChanges(newVal) {
    if (this.mainFieldTarget.value !== newVal) {
      this.dispatch("changed", { detail: { content: newVal } })
    }
  }

  _checkIfAllFilled() {
    if (this._allFieldsAreFilled()) {
      this.dispatch("filled", { detail: { content: this.mainFieldTarget.value } })
    }
  }

  _isValidOtpField(e) {
    return this._isAllowedKeys(e) || this._isAllowedValue(e.key)
  }

  _isAllowedKeys(e) {
    const key = e.key
    const isModifier = e.ctrlKey || e.metaKey
    return (
      key === "Backspace" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Enter" ||
      (isModifier && key === "v") || // Allow Ctrl+V and Command+V
      (isModifier && key === "r") // Allow reload
    )
  }

  _isAllowedValue(inputValue) {
    //|| ("a" <= inputValue && inputValue <= "z")
    return inputValue >= "0" && inputValue <= "9" // Allows only numeric input
  }

  _allFieldsAreFilled() {
    return this.mainFieldTarget.value.length === this.codeLengthValue
  }

  _hideCaret() {
    const inputs = this.element.querySelectorAll("input")
    inputs.forEach((input) => {
      input.style.caretColor = "transparent"
    })
  }
}
