import { cable } from "@hotwired/turbo-rails"
import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["searchInput", "kpId", "movieArticle"]
  static debounces = ["sendResultToServer"]
  static values = {
    toggleUrl: String,
  }

  connect() {
    useDebounce(this, { wait: 500 })
    this.moviesChannel = cable.subscribeTo("MoviesChannel")
  }

  async sendResultToServer() {
    const consumer = await this.moviesChannel
    consumer?.perform("find_by_keyword", {
      input_data: this.searchInputTarget.value,
    })
  }

  async chooseMovie(event) {
    const jsonMovie = event.params.movieObject
    this.clearSelection()

    if (jsonMovie.name_ru === "null") {
      this.searchInputTarget.value = jsonMovie.name_en
    } else {
      this.searchInputTarget.value = jsonMovie.name_ru
    }

    this.kpIdTarget.value = jsonMovie.kp_id
    const movieId = `#movie-${jsonMovie.kp_id}`
    const movieWrapper = document.querySelector(movieId)
    movieWrapper.classList.remove("border-gray-700", "bg-gray-800")
    movieWrapper.classList.add("border-green-500", "bg-gray-700")
    this.searchInputTarget.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  clearSelection() {
    this.movieArticleTargets.forEach((article) => {
      article.classList.remove("border-green-500", "bg-gray-700")
      article.classList.add("border-gray-700", "bg-gray-800")
    })
  }

  toggleType() {
    get(this.toggleUrlValue, {
      responseKind: "turbo-stream",
    })
  }
}
