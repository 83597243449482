import { Controller } from "@hotwired/stimulus"
import {
  AsYouType,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  parsePhoneNumber,
} from "libphonenumber-js/mobile"

// Connects to data-controller="phoneinput"
export default class extends Controller {
  static values = {
    country: {
      type: String,
      default: "RU",
    },
    errorClass: {
      type: String,
      default: "field_with_errors",
    },
    strict: {
      type: Boolean,
      default: true,
    },
    old: { type: String, default: "" },
  }

  connect() {
    if (this.isValid()) {
      this.format()
    }
    this.element.addEventListener("input", (e) => {
      this.format(e)
    })
  }

  format(e) {
    if (this.strictValue) {
      this.element.value = new AsYouType(this.countryValue).input(this.element.value)
      this.validate()
    } else {
      if (this.isValid()) {
        this.oldValue = this.element.value
        this.element.value = new AsYouType(this.countryValue).input(this.element.value)
      } else {
        if (this.oldValue) {
          this.element.value = this.oldValue
          if (e?.data) {
            e.target.value += e.data
          }
          this.oldValue = ""
        }
      }
    }
  }

  number() {
    return parsePhoneNumber(this.element.value, this.countryValue).number
  }

  validate() {
    if (this.isValid()) {
      this.element.parentElement.classList.remove(this.errorClassValue)
    } else {
      this.element.parentElement.classList.add(this.errorClassValue)
    }
  }

  isValid() {
    return (
      isPossiblePhoneNumber(this.element.value, this.countryValue) &&
      isValidPhoneNumber(this.element.value, this.countryValue)
    )
  }
}
