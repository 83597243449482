import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["controlInput", "textElement", "submitButton"]
  static values = {
    expires: Number,
    timeoutTitle: String,
    disabledClass: String,
    enabledClass: String,
  }

  connect() {
    this.originalText = this.textElementTarget.textContent
    this.timeoutButtonState()
    if (this.expiresValue > 0) {
      this.updateCountdown(this.expiresValue)
      this.startCountdown(this.expiresValue)
    } else {
      this.enableButton()
    }
  }

  timeoutButtonState() {
    this.controlInputTargets.forEach((controlInput) => {
      controlInput.disabled = true
    })
    this.textElementTarget.disabled = true
    this.textElementTarget.className = this.disabledClassValue
  }

  enableButton() {
    this.controlInputTargets.forEach((controlInput) => {
      controlInput.disabled = false
    })
    this.textElementTarget.disabled = false
    this.textElementTarget.className = this.enabledClassValue
    this.textElementTarget.textContent = this.originalText
  }

  updateCountdown(seconds) {
    this.textElementTarget.textContent = `${this.timeoutTitleValue} (${seconds})`
  }

  startCountdown(seconds) {
    const countdownInterval = setInterval(() => {
      seconds -= 1
      this.updateCountdown(seconds)

      if (seconds <= 0) {
        clearInterval(countdownInterval)
        this.enableButton()
      }
    }, 1000)
  }
}
