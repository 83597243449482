import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"

export default class extends Controller {
  static outlets = ["phoneinput"]
  static targets = ["loginInput", "smsButton"]
  static values = {
    url: {
      type: String,
      default: "",
    },
  }

  connect() {
    this.toggleSmsButton()
  }

  submit() {
    this.element.submit()
  }

  handleButtonClick() {
    put(this.urlValue, {
      responseKind: "turbo-stream",
      body: new FormData(this.element),
    })
  }

  toggleSmsButton() {
    if (this.phoneinputOutlet.isValid()) {
      this.loginInputTarget.classList.remove("rounded-lg")
      this.loginInputTarget.classList.add("rounded-s-lg")
      this.smsButtonTarget.classList.remove("hidden")
      // this.updateSmsButtonLink()
    } else {
      this.loginInputTarget.classList.remove("rounded-s-lg")
      this.loginInputTarget.classList.add("rounded-lg")
      this.smsButtonTarget.classList.add("hidden")
    }
  }

  updateSmsButtonLink() {
    let params = { send_sms: true, user: { login: this.phoneinputOutlet.number() } }
    let queryString = new URLSearchParams(params).toString()
    this.smsButtonTarget.href = `${this.urlValue}?${queryString}`
  }
}
