import ReadMore from "@stimulus-components/read-more"
import { useResize } from "stimulus-use"
import "./index.css"

export class Controller extends ReadMore {
  static targets = ["content", "button"]
  static values = {
    contentClass: String,
    moreText: String,
    lessText: String,
  }

  connect() {
    this.buttonTarget.innerHTML = this.moreTextValue
    useResize(this)
    this.open = false
    this.contentTarget.classList.add(this.contentClassValue)
  }

  toggle() {
    this.open = !this.open
    this.buttonTarget.innerHTML = this.open ? this.lessTextValue : this.moreTextValue
    this.contentTarget.classList.toggle(this.contentClassValue, !this.open)
  }

  resize() {
    if (!this.open && this.contentTarget.scrollHeight <= this.contentTarget.clientHeight) {
      this.buttonTarget.style.display = "none"
    } else {
      this.buttonTarget.style.display = "inline-block"
    }
  }
}
