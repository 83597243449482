import { Controller } from "@hotwired/stimulus"
import "slim-select/styles"
import { post } from "@rails/request.js"

// Connects to data-controller="user-selector"
export default class extends Controller {
  static params = ["userId"]
  connect() {}

  load({ params: { userId } }) {
    let act = this.element.checked ? "append" : "remove"

    post("/admin/seances_staff/form_control", {
      responseKind: "turbo-stream",
      query: { user_id: userId, act },
    })
  }
}
